<script>
import {
  ref,
  onMounted,
  watch,
} from '@vue/composition-api';
import { useLang } from '@/utils/hooks';
import { sessionGetItem } from '@/utils/utils'
import css from './DxCaptcha.scss';

let isLoad = false;
let widgetId = ""
function createDxCaptcha() {
  if (typeof document === 'undefined') return null;
  const e = document.createElement('script');
  e.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
  // ie10 不支持异步加载
  e.defer = true;
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(e);
  window.onloadTurnstileCallback = () => {
  }

  return new Promise((resolve) => {
    e.onload = () => {
      isLoad = true;
      resolve();
    };
  });
}

function initDxCaptcha(lang) {
  return new Promise(resolve => {
    turnstile.remove(widgetId)
    widgetId = turnstile.render('#verify-container', {
      sitekey: '0x4AAAAAAAhD26BqDX-ptrYU',
      language: lang.value == 'zh' ? 'zh-cn' : lang.value == 'zh_tw' ? 'zh-tw' : lang.value,
      callback: (token) => resolve(token)
    })    
  })
}

export default {
  model: { prop: 'dxToken', event: 'change' },
  props: {
    dxToken: { type: String, require: true },
    networkError: { type: String, required: true },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const verifyBtn = ref(null);

    const lang = useLang();

    const dxCaptcha = async () => {
      const enabled = sessionGetItem('dx-captcha');
      if(enabled === '0') {
        return emit('change', '123');
      }
      isLoad || await createDxCaptcha();
      const dxToken = await initDxCaptcha(lang);
      emit('change', dxToken);
    };

    watch(lang, () => {
      dxCaptcha();
    });

    onMounted(() => {
      dxCaptcha();
    });

    return {
      verifyBtn,
    };
  },
  render() {
    return <div id="verify-container" ref="verifyBtn" class={css['verify-btn']}></div>;
  },
};
</script>
